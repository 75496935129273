import theme from '../../theme'

const styles = {
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    nameList: {
        margin: "10px",
        marginBottom: '40px',
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        textAlign: "center"
    },
    typography: {
        fontFamily: 'Uniform',
        fontSize: '32px',
        fontWeight: 'bold',
        marginBottom: '15px'
    },
    subHeaderText: {
        fontFamily: 'Uniform',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    nameContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    placesContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: 'column',
            marginBottom: '40px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: '40px',
            flexDirection: 'column'
        },
    },
    stayingContainer: {
        display: 'flex',
        flex: '1',
        flexWrap: 'wrap',
        [theme.breakpoints.between("xs", "sm")]: {
            marginBottom: '40px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: '40px',
        },
    },
    nameItem: {
        fontSize: "18px",
        fontFamily: 'Uniform',
        margin: "5px",
        padding: "15px 15px",
        border: "1px solid #ddd",
        borderRadius: "3px",
        backgroundColor: "#f0f0f0"
    }
}

export default styles