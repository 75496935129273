import { Box, CardMedia, Typography } from "@mui/material"
import styles from "./DateCountdown.style"
import cornhill from "../../assets/images/cornhill.jpg"


const DateCountdown = () => {

    let weddingDate = new Date('06/21/2024');
    let todaysDate = new Date();

    const days = (date_1, date_2) => {
        let difference = weddingDate.getTime() - todaysDate.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }
    const daysTillWedding = days(weddingDate, todaysDate)

    return (
        <Box sx={styles.mainContainer}>
            <CardMedia component="img" image={cornhill} sx={styles.mainTextContainer} />
            <Box sx={styles.headingAndDateContainer}>
                <Box sx={styles.headingBox}>
                    <Typography sx={styles.headingText}>Days till wedding</Typography>
                </Box>
                <Box sx={styles.dateBox}>
                    <Typography sx={styles.dateText}>{daysTillWedding}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default DateCountdown