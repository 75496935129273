/* tslint:disable */
import { Box } from "@mui/material";
import React from 'react'
import { PhotoAlbum } from "react-photo-album";
import type { Photo } from "react-photo-album";
const photo1 = require('./images/2011.jpg');
const photo2 = require('./images/2012.jpg');
const photo3 = require('./images/2013.jpg');
const photo4 = require('./images/2014.jpg');
const photo5 = require('./images/2015.jpg');
const photo6 = require('./images/2016.jpg');
const photo7 = require('./images/2017.jpg');
const photo8 = require('./images/2018.jpg');
const photo9 = require('./images/2019.jpg');
const photo10 = require('./images/2020.jpg');
const photo11 = require('./images/2021.jpg');
const photo12 = require('./images/2022.jpg');

const test = [
    { src: photo1, width: 714, height: 401, title: '2011' },
    { src: photo2, width: 960, height: 720, title: '2012' },
    { src: photo3, width: 960, height: 720, title: '2013' },
    { src: photo4, width: 589, height: 765, title: '2014' },
    { src: photo5, width: 720, height: 720, title: '2015' },
    { src: photo6, width: 719, height: 960, title: '2016' },
    { src: photo7, width: 768, height: 1024, title: '2017' },
    { src: photo8, width: 768, height: 1024, title: '2018' },
    { src: photo9, width: 768, height: 1024, title: '2019' },
    { src: photo10, width: 768, height: 1024, title: '2020' },
    { src: photo11, width: 1024, height: 768, title: '2021' },
    { src: photo12, width: 819, height: 1024, title: '2022' },
];

const photos: Photo[] = test.map((photo) => ({
    src: photo.src,
    width: photo.width,
    height: photo.height,
    title: photo.title
})
);

const TimeLine2 = () => {
    return (
        <Box sx={{ width: '90%', height: '100%', maxWidth: '1440px' }}>
            <PhotoAlbum
                layout="rows"
                targetRowHeight={500}
                photos={photos}
                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                    <div style={{ position: "relative", ...wrapperStyle }}>
                        {renderDefaultPhoto({ wrapped: true })}
                        {photo.title && (
                            <div
                                style={{
                                    position: "absolute",
                                    overflow: "hidden",
                                    backgroundColor: "rgba(255 255 255 / .2)",
                                    inset: "auto 0 0 0",
                                    padding: 8,
                                    fontFamily: 'Coventry',
                                    fontSize: '30px',
                                    color: 'white'
                                }}
                            >
                                {photo.title}
                            </div>
                        )}
                    </div>
                )}
                columns={(containerWidth) => {
                    if (containerWidth < 800) return 1;
                    return 3;
                }} />
        </Box>
    )
}

export default TimeLine2

