import { Box, Fade } from "@mui/material"
import { useEffect } from "react"
import { useNavigate } from "react-router"
import Logo from "../../assets/images/EJLogo.png"
import styles from './LandingPage.style'
import theme from '../../theme.js'

const containerStyle = {
    ...styles.animationBox,
    backgroundColor: theme.palette.blue.background,
};

const LandingPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/home")
        }, 3000);
        return () => clearTimeout(timer);
    })
    return (
        <>
            <Fade in timeout={1500}>
                <Box sx={containerStyle}>
                    <Box
                        component="img"
                        sx={{
                            height: 600,
                            width: 600,
                            [theme.breakpoints.between("xs", "sm")]: {
                                height: 250,
                                width: 250,
                            },
                            [theme.breakpoints.between("sm", "md")]: {
                                height: 250,
                                width: 250,
                            },
                        }}
                        alt="Emma and Joel logo"
                        src={Logo}
                    />
                </Box>
            </Fade>
        </>
    )
}

export default LandingPage