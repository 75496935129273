import { Box } from '@mui/material';
import { Parallax } from 'react-parallax';
import styles from "./ImageTwo.style"

const Container2 = ({ image, children }) => {
    return (
        <Parallax blur={5} bgImage={image} strength={800} bgImageStyle={{
            objectFit: 'cover',
            height: '1000px',
            width: '100%',
        }}>
            <Box sx={styles.heroContainer}>
                {children}
            </Box>
        </Parallax>
    );
}

export default Container2