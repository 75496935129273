import { Box } from "@mui/material"
import React from "react"
import styles from "./DestinationCard.style"
import DateCountdown from "../DateCountdown/DateCountdown"

const DestinationCard = () => {
    return (
        <>
            <Box sx={styles.cardContainer}>
                <DateCountdown />
            </Box>
        </>
    )
}

export default DestinationCard