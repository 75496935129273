import { Box, Button, Typography } from "@mui/material"
import styles from "./EventDetails.style"
import Diversity1Icon from '@mui/icons-material/Diversity1';
import WatchIcon from '@mui/icons-material/Watch';
import PinDropIcon from '@mui/icons-material/PinDrop';
import theme from "../../theme"


const EventDetails = () => {

    const onClickMapButton = () => {

        window.open('https://www.google.com/maps/dir//Cornhill+Castle+Hotel,+Coulter+Rd,+Biggar/@55.6012734,-3.6300466,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4887fd3b8ccc1d39:0xb5d01480219490d1!2m2!1d-3.5600077!2d55.6011964');
    }

    return (
        <Box sx={styles.eventsContainer}>
            <Box sx={styles.thingsBox}>
                <Diversity1Icon sx={styles.iconStyle} />
                <Typography sx={styles.headingText}>WHO</Typography>
                <Typography sx={styles.paragraphText}>Our dearest friends & family</Typography>
                <Typography sx={styles.subParagraphText}>From near and far</Typography>
                <Box sx={{ height: '28px' }} />

            </Box>
            <Box sx={styles.thingsBox}>
                <WatchIcon sx={styles.iconStyle} />
                <Typography sx={styles.headingText}>WHEN</Typography>
                <Typography sx={styles.paragraphText}>Friday 21st June 2024</Typography>
                <Typography sx={styles.subParagraphText}>Ceremony starts 2PM</Typography>
                <Box sx={{ height: '28px' }} />
            </Box>
            <Box sx={styles.thingsBox}>
                <PinDropIcon sx={styles.iconStyle} />
                <Typography sx={styles.headingText}>WHERE</Typography>
                <Typography sx={styles.paragraphText}>Cornhill Castle</Typography>
                <Typography sx={styles.subParagraphText}>Biggar</Typography>
                <Button onClick={onClickMapButton} variant="outlined" sx={{ height: '28px', color: theme.palette.gold.main, borderColor: theme.palette.gold.main }}>See on map</Button>
            </Box>
        </Box>
    )
}

export default EventDetails