import theme from "./../../../theme"

const styles = {

    image: {
        minHeight: '100vh',
        position: 'relative',
    },

    imageStyle: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
    },

    heroContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: '8em',
        // paddingBottom: '1em'
    },

    imageTextStyle: {
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginBottom: 50,
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.between("xs", "sm")]: {
            width: '100%', padding: '5px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: '100%', padding: '5px'
        },

    },
    bgImageStyle: {
        objectFit: 'cover',
        height: 'auto',
        opacity: 0.9,
        minHeight: "100vh",
        width: '100%'
        // minWidth: "100vw",
        // [theme.breakpoints.between("xs", "sm")]: {
        //     left: '60%',
        //     opacity: 0.1
        // },
        // [theme.breakpoints.between("sm", "md")]: {
        //     left: '60%',
        //     opacity: 0.1

        // },
    },
    cardText: {
        fontFamily: 'Poiret',
        fontWeight: 700,
        letterSpacing: 0,
        textTransform: 'uppercase',
        textAlign: 'center',
        // color: theme.palette.blue.main,
        color: 'white',
        fontSize: '8rem',

        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '2rem',
            fontWeight: 1000,

        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '3rem',
            fontWeight: 1000,

        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '5rem',
            fontWeight: 1000,

        },
    },
    subHeader: {
        fontFamily: 'Surveyor',
        fontWeight: 500,
        letterSpacing: 0,
        textTransform: 'none',
        color: 'white',
        fontSize: '2rem',

        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '2rem'
        },
        "& :last-of-type": {
            marginTop: "auto"
        }
    },

    h3Style: {
        letterSpacing: '6px',
        textTransform: 'uppercase',
        font: '1.3rem',
        textAlign: 'center',
    },

    textBoxStyle: {
        textAlign: 'center',
        padding: '3rem',
    },

}

export default styles