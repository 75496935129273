import React from "react";
import { Box, useTheme } from "@mui/material";
import Container from "../../components/ParallaxImages/ParallaxImageOne/ImageOne";
import Container2 from "../../components/ParallaxImages/ParallaxImageTwo/ImageTwo";
import PageBanner from "../../components/PageBanner/PageBanner";
import EventDetails from "../../components/EventDetails/EventDetails";
import HomeRSVPSection from "../../components/HomeRSVPSection/HomeRSVPSection"
import sorrento from "../../assets/images/SORRENTO2.jpg"
import castle from "../../assets/images/cornhill.jpg"
import coast from "../../assets/images/coast.jpg"
import DestinationCard from "../../components/DestinationCard/DestinationCard";
import GiftsSection from "../../components/GiftsSection/GiftsSection"
import Timeline2 from "../../components/Timeline2/TimeLine2.tsx";
import Navbar from "../../components/TestNavBar/Navbar.tsx";
import Footer from "../../components/Footer/Footer";

const Home = () => {
    const theme = useTheme()

    return (
        <>
            <Navbar />
            <Container />
            <PageBanner>
                <EventDetails />
            </PageBanner>
            <Container2 image={sorrento}>
                <DestinationCard />
            </Container2>
            <PageBanner>
                <Timeline2 />
            </PageBanner>
            <Container2 image={castle} >
                <Box sx={{ height: '720px' }} />
            </Container2>
            <PageBanner style={{ backgroundColor: '#e5e5e5' }}>
                <GiftsSection />
            </PageBanner>
            <Container2 image={coast} >
                <Box sx={{ height: '720px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </Container2>
            <PageBanner style={{ backgroundColor: theme.palette.blue.lightblue, maxHeight: '800px' }}>
                <HomeRSVPSection />
            </PageBanner>
            <Footer />
        </>
    )
}

export default Home