import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import styles from './HomeRSVPSection.style.js'

const ring = require('./images/EJLogo.png')

const HomeRSVPSection = () => {

    return (
        <Box sx={styles.container}>
            <Box sx={styles.imageContainer}>
                <img
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    src={ring}
                    alt="rings"
                />
            </Box>
            <Box sx={styles.textContainer}>
                <Typography sx={styles.text}>
                    Join us on our special day!
                </Typography>
                <Button href="/rsvp" variant="contained" color="primary" sx={styles.button}>
                    RSVP
                </Button>
            </Box>


        </Box>
    );
};

export default HomeRSVPSection;
