import { Box, styled } from "@mui/material"
import RSVPForm from '../../components/RSVPForm2/RSVPForm2'
import Navbar from "../../components/TestNavBar/Navbar.tsx"

const ContainerBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "white",
    width: '100vw',
    paddingTop: '150px',
    paddingBottom: '100px',
    paddingLeft: '10px',
    paddingRight: '10px'
})

const RSVPPage = () => {

    return (
        <>
            <Navbar />
            <ContainerBox>
                <RSVPForm />
            </ContainerBox>
        </>

    )
}

export default RSVPPage