import theme from "./../../../theme"

const styles = {

    heroContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        maxWidth: '1440px',
        height: '80%',
        maxHeight: '80%',
        backgroundColor: theme.palette.blue.lightblue,
        padding: theme.padding.standardPadding,
        margin: '50px'
    },

    imageTextStyle: {
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginBottom: 50,
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.between("xs", "sm")]: {
            width: '100%', padding: '5px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: '100%', padding: '5px'
        },

    },
    cardText: {
        fontFamily: 'Surveyor',
        fontWeight: 700,
        letterSpacing: 0,
        textTransform: 'none',
        color: theme.palette.blue.main,
        fontSize: '3rem',

        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '3rem'
        },
    },
    subHeader: {
        fontFamily: 'Surveyor',
        fontWeight: 500,
        letterSpacing: 0,
        textTransform: 'none',
        color: theme.palette.blue.ternary,
        fontSize: '2rem',

        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '2rem'
        },
        "& :last-of-type": {
            marginTop: "auto"
        }
    },

    h3Style: {
        letterSpacing: '6px',
        textTransform: 'uppercase',
        font: '1.3rem',
        textAlign: 'center',
    },

    textBoxStyle: {
        textAlign: 'center',
        padding: '3rem',
    },

}

export default styles