import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from './DashboardPage.style'

const NameList = ({ title, names, totalCount }) => {

    return (
        <Box sx={styles.nameList}>
            <Typography sx={styles.typography}>{title} - {names.length}/{totalCount}</Typography>
            <Box sx={styles.nameContainer}>
                {names.map((name, index) => (
                    <Box key={index} sx={styles.nameItem}>
                        {name}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default NameList;