import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom'
import Home from './pages/HomePage/Home';
import "animate.css/animate.min.css";
import Dashboard from './pages/DashboardPage/DashboardPage'
import FAQS from './pages/FAQS/FAQS';
import LandingPage from './pages/Landing/LandingPage';
import RSVPPage from './pages/RSVPPage/RSVPPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/faqs' element={<FAQS />} />
        <Route path="/rsvp" element={<RSVPPage />} />
      </Routes>
    </Router>
  );
}

export default App;
