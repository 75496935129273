import { Box } from "@mui/material"
import styles from "./PageBanner.style"

const PageBanner = ({ style, children }) => {
    const bannerStyles = {
        ...style,
        ...styles.pageBannerStyle
    }

    return (
        <Box sx={bannerStyles}>
            {children}
        </Box >
    )
}

export default PageBanner