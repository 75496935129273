const styles = {
    animationBox: {
        display: 'flex',
        zIndex: '1200',
        postiion: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }
}

export default styles