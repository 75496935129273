import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import axios from 'axios';
import NameList from "./Nameslist";
import PlacesStayingList from "./PlacesStayingList";

const ContainerBox = styled("Box")({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "#a4bccc",
    height: '100%',
    width: '100vw',
    paddingTop: '150px'
})

const Dashboard = () => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        axios.get(`https://wedding-db-jande-206414ee999a.herokuapp.com/api/getAll`).then((data) => {
            setResults(data.data);
        })
    }, [])

    const repliedAttending = results.filter((person) => {
        return person.attending === 1
    })

    const repliedNotAttending = results.filter((person) => {
        return person.replied && person.attending === 0
    })

    const notReplied = results.filter((person) => {
        return !person.replied
    })

    const lookingForTransport = results.filter((person) => {
        return person.transport
    })

    return (
        <ContainerBox>
            <NameList title="Replied and Attending" totalCount={results.length} names={repliedAttending.map((person) => person.name)} />
            <NameList title="Replied and Not Attending" totalCount={results.length} names={repliedNotAttending.map((person) => person.name)} />
            <NameList title="Not Replied" totalCount={results.length} names={notReplied.map((person) => person.name)} />
            <NameList title="Looking for transport" totalCount={results.length} names={lookingForTransport.map((person) => person.name)} />
            <PlacesStayingList names={repliedAttending} />
        </ContainerBox>
    )
}

export default Dashboard