import theme from "../../theme"

const styles = {
    inputStyle: {
        margin: "5px",
        backgroundColor: 'white',
        borderRadius: 4,
        [theme.breakpoints.between("xs", "sm")]: {
            width: "300px", // 20px
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "400px", // 20px
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: "500px", // 20px
        },
        [theme.breakpoints.up("lg")]: {
            width: "600px", // 20px
        },
    },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        minWidth: '1440px',
        [theme.breakpoints.between("xs", "sm")]: {
            minWidth: '250px',
            padding: '20px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            minWidth: '250px',
            padding: '20px'

        },
    },
    textContainer: {
        maxWidth: '720px',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    headerText: {
        fontFamily: "Uniform",
        fontSize: '14px',
        letterSpacing: '2px',
        fontWeight: 500,
        textTransform: 'uppercase',
        marginBottom: '20px',
        lineHeight: 1.9
    },
    acceptedHeaderText: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        letterSpacing: '2px',
        fontWeight: 300,
        textTransform: 'uppercase',
        marginBottom: '40px'
    },
    paragraphText: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        letterSpacing: '2px',
        fontWeight: 300,
        textTransform: 'uppercase',
        marginBottom: '20px'
    },
    initialSubmitContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        justifyContent: 'space-evenly',
        width: '100%',
        maxWidth: '720px',
        [theme.breakpoints.between("xs", "sm")]: {
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '300px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: '300px'

        },
    },
    guestNames: {
        width: '100%',
        maxWidth: '720px',
        [theme.breakpoints.between("xs", "sm")]: {
            marginBottom: '20px',
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px',
            marginBottom: '20px'
        },
    },
    attendanceButton: {
        maxWidth: '360px',
        width: '100%',
        height: '60px',
        color: 'grey',
        borderColor: theme.palette.gold.main,
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.gold.main,
            color: 'white'
        },
        '&.MuiButton-outlined': {
            color: 'grey',
            '&:hover': {
                borderColor: theme.palette.gold.main,
            },
        },
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '150px',
            fontSize: '12px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '150px'

        },
    },
    divider: {
        height: '1px',
        backgroundColor: 'gray',
        width: '100%',
        maxWidth: '720px',
        marginBottom: '20px',
        marginTop: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    buttonGroupStyling:
    {
        maxWidth: '720px',
        width: '100%',
        marginBottom: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    dietaryTextContainer: {
        marginBottom: '20px',
        width: '100%',
        maxWidth: '720px',
        '&.MuiTextField-focused': {
            borderColor: theme.palette.gold.main,
            color: 'green'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.gold.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.gold.main,
                color: 'red'
            },
        },
        '& label.Mui-focused': {
            color: 'grey',
        },
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '720px',
        width: '100%',
        '& .MuiMenu-paper': {
            backgroundColor: 'dark.primary',
            color: 'text.light'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'dark.secondary',
            color: 'text.white'
        },
        '& .Mui-selected': {
            backgroundColor: 'red',
            color: 'green'
        },
        '&:hover': {
            ':before': {
                borderBottom: `1px solid red`
            }
        },
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    formControl: {
        width: '100%',
        maxWidth: '720px',
        marginTop: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    successResponseBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '720px',
        marginTop: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    },
    successIcon: {
        color: theme.palette.gold.main,
        marginBottom: '20px',
        fontSize: '500px',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '300px'
        },
    },
    responseText: {
        textAlign: 'center',
        fontSize: '16px',
        letterSpacing: '2px',
        fontFamily: 'Uniform',
        fontWeight: 300,
        marginBottom: '40px',
        marginTop: '20px',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '14px',
            maxWidth: '300px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px'
        },
    }
}

export default styles