import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Link, Typography } from '@mui/material';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    marginBottom: 10,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem', color: 'black' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '20px',
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const StyledTypography = styled(Typography)(() => ({
    padding: '0px 10px 0px 20px',
    fontFamily: "Uniform"
}))

const FAQSection = () => {

    const widthage = 1440 / 3

    return (
        <Box sx={{ maxWidth: '1440px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>How do I RSVP?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        We sure hope you’ll be able to join us for your special day.
                        With your invitation being through this website,
                        there is a RSVP tab for you to fill in that is sent directly to us.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>RSVP Deadline? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        Please get us your RSVP by March 1st 2024 so we can finalise our numbers with the venue.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Can I bring my kids?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        While we love your little ones, our wedding is going to be an adults-only event so that everyone
                        can relax and enjoy the evening. We appreciate you making arrangements ahead of time and leaving
                        the kids at home so you can celebrate with us.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Plus one?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        We have a strict guest list to stay on budget.  Our wedding is strictly RSVP only. We will only able to accommodate those listed on your invitation.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Accomodation?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        For those not planning on going home via arranged transport
                        rooming within the castle and the lodges on site are subject to availability and allocated by the Bride & Groom. If they have not contacted you around this then you can enquire at the hotel just around the corner. This is also subject to availability. https://kirkstylehotel.com
                        Please note: if you do end up booking to stay, please re-complete the RSVP and state you no longer require transport.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Transport?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        We are providing formal transportation for the event that will collect guests from Airdrie Town Hall and The Time Capsule and will drop you at our wedding venue. There will also be transport returning guests to these locations and this leaves Cornhill Castle at midnight. Collection times will be sent to you directly if you have stated in the RSVP you require transport.
                        Please note: we will only be providing the coaches for those that have stated they require this so please update this even if you change your mind!
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Parking?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        There is ample parking for those who feel comfortable driving and for those who are staying at the castle with us! Please stay safe and default to a taxi or carpooling if you plan to drink.
                        A taxi service runs in Biggar too but this must be pre booked in advance: 07747 - 620313.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Dress code?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        There is a formal dress code but please wear what you're comfortable with and get ready to dance ALL night (P.S. no white!).
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Gifts?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        Your presence on our wedding day is the most important gift you could ever give us. However,
                        if you would like to consider a gift please see our suggestions by clicking this <Link href="https://prezola.com/wishlists/10282756/" >link</Link>.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Alcohol?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        Unfortunately this is an alchohol free wedding so no booze is permitted...
                        <br />
                        <br />
                        Just kidding!
                        <br />
                        <br />
                        We will be providing drinks with the meal and there’s a bar to buy yourself whatever you need.
                        Please note: this is a cashless venue so only cards can be used inside the castle.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: `${widthage}px`, maxWidth: '100vw' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '40px', fontFamily: 'Poiret' }}>Other questions?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        If you have any other question please feel free to get in touch with the bride or groom via:
                        <br />
                        <br />
                        Email
                        <br /> emmaandjoelwedding@gmail.com<br />
                        <br />
                        Text<br />
                        07538054150
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default FAQSection