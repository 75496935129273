import { Box, Typography, styled } from "@mui/material"
import FAQSection from '../../components/FAQSection/FAQSection.tsx'
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/TestNavBar/Navbar.tsx"
import theme from "../../theme"

const ContainerBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "white",
    height: '100vh',
    width: '100vw',
    paddingTop: '150px'
})

const FAQS = () => {

    return (
        <>
            <Navbar />
            <ContainerBox>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '60px',
                    letterSpacing: '0.1em',
                    paddingTop: theme.padding.smallerPadding,
                    paddingBottom: theme.padding.smallerPadding,
                    color: theme.palette.gold.main,
                }} >FAQS</Typography>
                <FAQSection />
            </ContainerBox>
            <Footer />
        </>

    )

}

export default FAQS