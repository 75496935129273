import { createTheme } from '@mui/material/styles';

let theme = createTheme()
theme = createTheme(theme, {
    palette: {
        primary: {
            main: '#1976d2'
        },
        blue: {
            main: '#182C3C',
            lightblue: '#a4bccc',
            ternary: '#4C5C6E',
            shadow: '#4C646C',
            background: '#40566F',
        },
        pink: {
            main: '#8c7979'
        },
        gold: {
            main: '#ccb4a4  '
        }
    },
    typography: {
        h1: {
            fontFamily: 'Surveyor',
            fontSize: "24px", //24px
            lineHeight: "34px", //34px
            color: "white",
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1.25rem", // 20px
                lineHeight: "1.9rem", //  30px
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "30px", //24px
                lineHeight: "2rem", // 35px
                color: 'white'
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "40px", //24px
                lineHeight: "2rem", // 35px
                color: 'white'
            },
        },
        menuText: {
            fontSize: "18px",
            color: "black",
        },
        bigHeading: {
            fontSize: '40px',
            fontFamily: 'Dancing Script'
        },
        bigHeading2: {
            fontFamily: 'Surveyor',
            fontSize: '40px'
        }
    },
    padding: {
        standardPadding: '40px',
        smallerPadding: '20px'
    }
})


export default theme;