import theme from "../../theme.js"

const styles = {

    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: 'column',
            maxWidth: '600px',
            width: '100%',
            lineHeight: "1.9rem", //  30px
            padding: '10px',

        },
        [theme.breakpoints.between("sm", "md")]: {
            flexDirection: 'column',
            lineHeight: "1.9rem", //  30px
            maxWidth: '900px',
            padding: '30px',

        },
        [theme.breakpoints.between("md", "lg")]: {
            lineHeight: "1.9rem", //  30px
            padding: '60px',
            maxWidth: '1200px'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '1560px',
            paddingTop: '100px',
            paddingBottom: '100px',
            paddingLeft: '60px',
            paddingRight: '60px'
        },
    },

    testBox: { width: '100%', height: '100%' },
    mainTextContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.blue.ternary,
        borderTopLeftRadius: 20,
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '600px',
            borderTopRightRadius: 20
        },
        [theme.breakpoints.between("sm", "md")]: {
            borderTopRightRadius: 20
        },
        [theme.breakpoints.between("md", "lg")]: {
            marginRight: '10px',
            borderBottomLeftRadius: 20,
            maxHeight: '400px'
        },
        [theme.breakpoints.up("lg")]: {
            marginRight: '10px',
            borderBottomLeftRadius: 20,
            width: '75%',
            height: '600px'
        },
    },
    headingAndDateContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '600px',
            flexDirection: 'row',
            paddingTop: '10px',
            height: '65px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '900px',
            flexDirection: 'row',
            paddingTop: '10px',
            justifyContent: 'space-between',
            height: '75px',
        },
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: '25%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '400px'
        },
        [theme.breakpoints.up("lg")]: {
            width: '25%',
            height: '600px'
        },

    },
    headingBox: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.blue.main,
        marginBottom: '10px',
        [theme.breakpoints.between("xs", "sm")]: {
            marginRight: '10px',
            borderBottomLeftRadius: 20,
            flex: 2
        },
        [theme.breakpoints.between("sm", "md")]: {
            borderBottomLeftRadius: 20,
            marginRight: '10px'
        },
        [theme.breakpoints.between("md", "lg")]: {
            borderTopRightRadius: 20
        },
        [theme.breakpoints.up("lg")]: {
            width: '100%',
            borderTopRightRadius: 20,
        },
    },
    dateBox: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.blue.main,
        [theme.breakpoints.between("xs", "sm")]: {
            flex: 1,
            borderBottomRightRadius: 20
        },
        [theme.breakpoints.between("sm", "md")]: {
            borderBottomRightRadius: 20
        },
        [theme.breakpoints.between("md", "lg")]: {
            borderBottomRightRadius: 20
        },
        [theme.breakpoints.up("lg")]: {
            width: '100%',
            borderBottomRightRadius: 20
        },
    },
    paragraphText: {
        fontFamily: 'Poiret',
        fontSize: "20px",
        color: 'white',
    },
    headingText: {
        fontFamily: 'Dancing Script',
        fontSize: '30px',
        color: 'white',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '25px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '30px'
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '30px'
        },
    },
    dateText: {
        fontFamily: 'Dancing Script',
        fontSize: '100px',
        color: 'white',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '25px',
            padding: '10px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '50px'
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '100px'
        },
    }
}

export default styles