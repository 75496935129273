import { Box, Typography, styled } from '@mui/material';
import { Parallax } from 'react-parallax';
import styles from "./ImageOne.style"
import theme from '../../../theme'
import SouthIcon from '@mui/icons-material/South';
import menem from '../images/menem.jpg';


const NotificationContainer = styled(Typography)(() => ({
    fontFamily: 'Poiret',
    fontWeight: 700,
    letterSpacing: 0,
    textTransform: 'none',
    color: 'white',
    fontSize: '2rem',
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: '1.5rem'
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: '1.5rem'
    },
    "&:last-of-type": {
        marginTop: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

const Container = () => (
    <Parallax blur={0} bgImage={menem} strength={800} bgImageStyle={{
        objectFit: 'cover',
        left: '50%',
        opacity: 0.9,
        width: '100%',
        height: '100%',
    }} renderLayer={percentage => (<div style={{ position: 'absolute', width: '100%', height: '100%', background: `rgba(0, 0, 0, ${percentage * 0.5})` }} />)}>
        <Box sx={styles.heroContainer}>
            <Typography sx={styles.cardText}>
                Emma & Joel
            </Typography>
            <NotificationContainer>
                OUR FAIRYTALE BEGINS
            </NotificationContainer>
            <NotificationContainer >
                Scroll to RSVP
                <SouthIcon sx={{ height: 40, width: 30, marginBottom: '20px', color: theme.palette.blue.primary }} />
            </NotificationContainer>
            {/* </Box> */}
            {/* </span> */}
        </Box>
    </Parallax >
);

export default Container