import theme from "../../theme"

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1440px',
        height: '100%',
        padding: '10px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
            marginBottom: '10px',
            flexDirection: 'column'
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '600px',
            padding: '10px',
            flexDirection: 'column'
        },
    },
    iconContainer: {
        height: '100%',
        width: '100%',
        maxHeight: '200px',
        maxWidth: '200px',
        marginBottom: '40px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxHeight: '100px',
            maxWidth: '100px'
        },
    },
    iconStyle: {
        height: '100%',
        width: '100%',
        fill: 'theme.palette.blue.shadow'
    },
    textContainer: {
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        textAlign: 'center',
        maxHeight: '400px',
        marginBottom: '60px',
    },
    text: {
        fontFamily: "Uniform",
        fontSize: '22px',
        color: 'black',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '18px'
        },
    },
    button: {
        maxWidth: '360px',
        borderRadius: 8,
        fontSize: '40px',
        width: '100%',
        height: '100px',
        color: 'white',
        backgroundColor: theme.palette.blue.lightblue,
        borderColor: theme.palette.blue.main,
        '&:hover': {
            backgroundColor: theme.palette.blue.shadow,
            color: theme.palette.gold.main
        },

        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '150px',
            height: '50px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '250px'
        },
    },
    buttonIcon: {
        fontSize: '60px',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '30px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '40px'
        },
    }
}

export default styles