import React from 'react'
import styles from './GiftsSection.style.js'
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as GiftIcon } from "./images/gift.svg"
import FavoriteIcon from '@mui/icons-material/Favorite';


const GiftsSection = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.iconContainer} >
                <SvgIcon component={GiftIcon} sx={styles.iconStyle} />
            </Box>
            <Box sx={styles.textContainer}>
                <Typography sx={styles.text}>
                    Your presence on our wedding day is the most important gift you could ever give us. However,
                    if you would like to consider a gift please see our suggestions by clicking the button below.
                </Typography>
            </Box>
            <IconButton href="https://prezola.com/wishlists/10282756/" sx={styles.button} variant="outlined">
                <FavoriteIcon sx={styles.buttonIcon} />
            </IconButton>
        </Box>
    )
}

export default GiftsSection