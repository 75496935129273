import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from './DashboardPage.style'

const PlacesStayingList = ({ names }) => {
    console.log('placesNamess', names)

    const stayingOrWantingCastle = names.filter((person) => {
        return person.staying === 'castle'
    })
    const goingHome = names.filter((person) => {
        return person.staying === 'home'
    })
    const nearbyFolk = names.filter((person) => {
        return person.staying === 'nearby'
    })

    return (
        <Box sx={styles.nameList} >
            <Typography sx={styles.typography}>Where folk are staying</Typography>
            <Box sx={styles.placesContainer} >
                <Typography sx={styles.subHeaderText}>Castle</Typography>
                <Box sx={styles.stayingContainer}>
                    {stayingOrWantingCastle.map((person, index) => (
                        <Box key={index} sx={styles.nameItem}>
                            {person.name}
                        </Box>
                    ))}
                </Box>
                <Typography sx={styles.subHeaderText}>Home</Typography>
                <Box sx={styles.stayingContainer}>
                    {goingHome.map((person, index) => (
                        <Box key={index} sx={styles.nameItem}>
                            {person.name}
                        </Box>
                    ))}
                </Box>
                <Typography sx={styles.subHeaderText}>Nearby</Typography>
                <Box sx={styles.stayingContainer}>
                    {nearbyFolk.map((person, index) => (
                        <Box key={index} sx={styles.nameItem}>
                            {person.name}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default PlacesStayingList