import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import styles from './RSVPForm2.style'
import axios from 'axios';
import theme from "./../../theme"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

interface Props {
  db: object
}

const RSVPForm2 = (props: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([] as any);
  const [retrievingError, setRetrievingError] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [dietaryValue, setDietaryValue] = useState("");
  const [selectValues, setSelectValues] = useState([] as any[]);
  const [contactNumber, setContactNumber] = useState<Number>()

  useEffect(() => {
    setResponseSuccess(false);
  }, [])

  const PersonButtonGroup = ({ person }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(person.attending);
    const [selectedTransportIndex, setSelectedTransportIndex] = useState(person.transport);

    const handleButtonClick = (personId, newIndex) => {
      setSelectedItemIndex(newIndex);

      setResults(prevResults => {
        return prevResults.map(person => {
          if (person.id === personId) {
            return { ...person, attending: newIndex };
          }
          return person;
        });
      });

    };

    const handleTransportClick = (personId, newIndex) => {
      setSelectedTransportIndex(newIndex)

      setResults(prevResults => {
        return prevResults.map(person => {
          if (person.id === personId) {
            return { ...person, transport: newIndex };
          }
          return person;
        });
      });
    }

    const handleSelectChange = (event, personId) => {
      const selectedIndex = selectValues.findIndex((item) => item.personId === personId);
      const updatedSelectValues = [...selectValues];

      if (selectedIndex !== -1) {
        updatedSelectValues[selectedIndex] = { personId, value: event.target.value };
      } else {
        updatedSelectValues.push({ personId, value: event.target.value });
      }

      setSelectValues(updatedSelectValues);

      setResults((prevResults) => {
        return prevResults.map((person) => {
          if (person.id === personId) {
            return { ...person, staying: event.target.value };
          }
          return person;
        });
      });
    };

    return (
      <Box key={person.id} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', maxWidth: '720px' }}>
        <Typography sx={styles.paragraphText}>
          {person.name}
        </Typography>
        <ButtonGroup sx={styles.buttonGroupStyling} key={`${person.id}-attending`}>
          <Button sx={styles.attendanceButton}
            variant={selectedItemIndex === 1 ? 'contained' : 'outlined'}
            onClick={() => handleButtonClick(person.id, 1)}
          >
            Will Attend
          </Button>
          <Button sx={styles.attendanceButton}
            variant={selectedItemIndex === 0 ? 'contained' : 'outlined'}
            onClick={() => handleButtonClick(person.id, 0)}
          >
            Will not attend
          </Button>
        </ButtonGroup>
        <ButtonGroup sx={styles.buttonGroupStyling} key={`${person.id}-transport`}>
          <Button sx={styles.attendanceButton}
            variant={selectedTransportIndex === 1 ? 'contained' : 'outlined'}
            onClick={() => handleTransportClick(person.id, 1)}
          >
            Require Transport
          </Button>
          <Button sx={styles.attendanceButton}
            variant={selectedTransportIndex === 0 ? 'contained' : 'outlined'}
            onClick={() => handleTransportClick(person.id, 0)}
          >
            Don't Require Transport
          </Button>
        </ButtonGroup>
        <Box sx={{ ...styles.textContainer, marginTop: 0, textAlign: 'left' }}>
          <Typography sx={{ color: 'grey', }}> We are running transportation from Airdrie/Coatbridge and back. If you require transport we will contact you with details nearer the time.</Typography>

        </Box>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="demo-simple-select-label">Staying?</InputLabel>
          <Select
            labelId="select-label"
            required
            id="simple-select"
            sx={styles.selectContainer}
            defaultValue={person.staying ? person.staying : selectValues}
            // value={selectValues.find((item) => item.personId === person.id)?.value || ""}
            label="Staying?"
            onChange={(e) => handleSelectChange(e, person.id)}
          >
            <MenuItem key={`${person.id}-placeholder`} disabled value="Where are you staying?"> Where are you staying?
            </MenuItem>
            <MenuItem key={`${person.id}-home`} value="home">I'm going home</MenuItem>
            <MenuItem key={`${person.id}-nearby`} value="nearby">I'm staying nearby</MenuItem>
            <MenuItem key={`${person.id}-castle`} value="castle">I'm looking to stay in the castle</MenuItem>
          </Select>
        </FormControl>
        <Box sx={styles.divider} />
      </Box>
    );
  }

  const handleDietaryChange = (e) => {
    setDietaryValue(e.target.value);
  }
  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
  }

  const createTodo = async (e, name: String) => {
    e.preventDefault();
    axios.get(`https://wedding-db-jande-206414ee999a.herokuapp.com/api/getAllInParty/${inputValue}`).then((data) => {
      setResults(data.data);
    }).catch(error => {
      console.log('Error retrieving person:', error);
      setRetrievingError(true);
    });
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const testAddtoFireStore = (event) => {
    event.preventDefault();
    const updatedPeople = results.filter(person => person.attending !== person.originalAttending);

    axios.put('https://wedding-db-jande-206414ee999a.herokuapp.com/api/updatePerson', {
      updatedPeople, dietaryValue, contactNumber
    })
      .then(response => {
        console.log('Database updated successfully');
        setResults([]);
        setResponseSuccess(true);
      })
      .catch(error => {
        console.log('Error updating database:', error);
        setResponseError(true);
      });
  };

  //@ts-ignore
  return (
    <>
      {!responseSuccess && !responseError && (
        <Typography sx={{
          fontWeight: 700,
          fontSize: '60px',
          letterSpacing: '0.1em',
          paddingTop: theme.padding.smallerPadding,
          paddingBottom: theme.padding.smallerPadding,
          color: theme.palette.gold.main,
        }} >RSVP</Typography>
      )}
      {results && results.length === 0 && !responseSuccess && !responseError && (
        //@ts-ignore 
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} component="form" onSubmit={createTodo}>
          <Box sx={styles.textContainer}>
            <Typography sx={styles.headerText}>
              Please enter the <b>first and last name</b> of one member of your party below.
              If you're responding for you and a guest (or your family),
              you'll be able to RSVP for your entire group.
            </Typography>
          </Box>
          <Box sx={styles.initialSubmitContainer}>
            <TextField
              sx={styles.dietaryTextContainer}
              label="Guest Name"
              value={inputValue}
              placeholder="First AND Last Name."
              helperText={retrievingError ? "Error retrieving details, please enter first AND last name" : null}
              multiline
              required
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button sx={styles.attendanceButton} type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {results.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} component="form" onSubmit={testAddtoFireStore}>
          <Box sx={styles.textContainer}>
            <Typography sx={styles.headerText}>
              We have invited your party to our wedding. Please fill in your RSVP below
            </Typography>
            <Box sx={styles.divider} />
            <Typography sx={{ fontFamily: 'Cardo', fontSize: '36px', marginBottom: '20px', marginTop: '40px' }}>
              Details
            </Typography>
            <Typography sx={styles.paragraphText}>
              Friday 21st June 2024
            </Typography>
            <Typography sx={styles.paragraphText}>
              Cornhill Castle
            </Typography>
          </Box>
          <Box sx={styles.divider} />
          {results.map((result) => (
            <React.Fragment key={result.id}>
              <PersonButtonGroup person={result} />
            </React.Fragment>
          ))}
          <TextField
            id="outlined-multiline-static"
            sx={styles.dietaryTextContainer}
            label="Dietary Requirements"
            multiline
            rows={4}
            onChange={handleDietaryChange}
            placeholder="If anyone in your party has additional dietary requirements then please let us know."
          />
          <TextField
            id="outlined-multiline-static"
            sx={styles.dietaryTextContainer}
            label="Contact Email"
            required
            type="email"
            onChange={handleContactNumberChange}
            placeholder="Enter a contact email."
            helperText="This is so we can contact you regarding transport/rooms or if any of the wedding details change."
          />
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button sx={{ marginBottom: '20px', ...styles.attendanceButton }} variant="contained" type="submit">
              Submit RSVP
            </Button>
          </Box>
        </Box>
      )}
      {responseSuccess && (
        <Box sx={styles.successResponseBox}>
          <CheckCircleOutlineIcon sx={styles.successIcon} />
          <Typography sx={{ fontFamily: 'Poiret', fontSize: '30px' }}>RSVP SUBMITTED</Typography>
          <Box sx={styles.divider} />
          <Typography sx={styles.responseText}>Thank you {inputValue}, your RSVP form has been successfully submitted. If any changes need to be made feel free to submit the form again. <br /><br /> If you have any additional questions feel free to contact us on <a href="mailto:emmaandjoelwedding@gmail.com">emmaandjoelwedding@gmail.com</a></Typography>
          <AddToCalendarButton
            name="Joel & Emma's Wedding"
            description="Celebrate our day"
            startDate="2024-06-21"
            startTime="11:30"
            endDate="2024-06-21"
            endTime="23:59"
            timeZone="Europe/London"
            location="Cornhill Castle"
            options="'Apple','Google','iCal','Outlook.com','Yahoo'"
            size="9"
          ></AddToCalendarButton>
          <Button sx={{ ...styles.attendanceButton, marginTop: '20px' }} variant="contained" href="/home">Home</Button>
        </Box>
      )}
      {responseError && (
        <Box sx={styles.successResponseBox}>
          <ErrorOutlineIcon sx={styles.successIcon} />
          <Typography sx={{ fontFamily: 'Cardo', fontSize: '30px' }}>Submission Error</Typography>
          <Box sx={styles.divider} />
          <Typography sx={{
            textAlign: 'center',
            fontSize: '16px',
            letterSpacing: '2px',
            fontWeight: 300,
            marginBottom: '20px',
          }}>Looks like something messed up and your RSVP was unable to be submitted. Please click the button below to return to the RSVP page to try again. If it still fails after that, please give it a few minutes, it's probably just something Joel set up being fucky.</Typography>
          <Button sx={styles.attendanceButton} variant="contained" href="/rsvp">Return</Button>
        </Box>
      )}
    </>
  );
}

export default RSVPForm2