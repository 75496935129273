import theme from "../../theme"

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1440px',
        height: '100%',
        maxHeight: '800px',
        backgroundSize: 'cover',
        marginBottom: '40px',
        padding: '10px',
        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '300px',
            marginBottom: '10px',
            flexDirection: 'column'
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '300px',
            padding: '10px',
            flexDirection: 'column'
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },

    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
        height: 'auto',
        textAlign: 'center',
        marginRight: '10px',
        [theme.breakpoints.up("lg")]: {
            maxWidth: '600px'
        },
    },
    imageContainer: {
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        maxHeight: '600px',
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '400px',
            maxHeight: '400px',
        },
        [theme.breakpoints.between("md", "lg")]: {
            maxWidth: '500px',
            maxHeight: '500px',
        },
    },
    text: {
        marginTop: '120px',
        fontFamily: 'Poiret',
        fontSize: '4rem',
        color: 'black',
        marginBottom: '50px',
        textTransform: 'uppercase',
        [theme.breakpoints.between("xs", "sm")]: {
            marginBottom: '40px',
            marginTop: '40px',
            fontSize: '2rem'
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: '40px',
            fontSize: '2rem'
        },
        [theme.breakpoints.between("md", "lg")]: {
            // marginTop: '10px',
            // marginTop: '40px',
            fontSize: '3rem'
        },
    },
    // button: {
    //     maxWidth: '360px',
    //     borderRadius: 8,
    //     fontSize: '40px',
    //     width: '100%',
    //     height: '100px',
    //     color: 'grey',
    //     borderColor: theme.palette.blue.main,
    //     '&.MuiButton-contained': {
    //         backgroundColor: theme.palette.blue.main,
    //         color: 'white',
    //         '&:hover': {
    //             backgroundColor: theme.palette.blue.shadow,
    //         },
    //     },
    //     '&.MuiButton-outlined': {
    //         color: 'grey',
    //         '&:hover': {
    //             borderColor: theme.palette.blue.main,
    //         },
    //     },
    //     [theme.breakpoints.between("xs", "sm")]: {
    //         maxWidth: '150px',
    //         fontSize: '20px'
    //     },
    //     [theme.breakpoints.between("sm", "md")]: {
    //         maxWidth: '150px'
    //     },
    // },
    button: {
        maxWidth: '360px',
        borderRadius: 8,
        fontSize: '20px',
        width: '100%',
        height: '100px',
        color: 'white',
        backgroundColor: theme.palette.blue.main,
        borderColor: theme.palette.blue.main,
        '&:hover': {
            backgroundColor: theme.palette.blue.shadow,
            color: theme.palette.gold.main
        },

        [theme.breakpoints.between("xs", "sm")]: {
            maxWidth: '150px',
            height: '50px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            maxWidth: '250px'
        },
    },
};

export default styles