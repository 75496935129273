import theme from "../../theme"

const styles = {
    eventsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1440px',
        [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.between("sm", "md")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.between("md", "lg")]: {
            flexDirection: 'column'
        },
    },
    thingsBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.between("xs", "sm")]: {
            marginBottom: 20,
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: 20,
        },
        [theme.breakpoints.between("md", "lg")]: {
            marginBottom: 20,
        },
    },
    headingText: {
        // fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '60px',
        letterSpacing: '0.1em',
        paddingTop: theme.padding.smallerPadding,
        paddingBottom: theme.padding.smallerPadding,
        // textDecoration: 'underline',
        color: theme.palette.gold.main,

    },
    paragraphText: {
        fontFamily: 'Poiret',
        fontWeight: 600,
        fontSize: '25px',
        color: theme.palette.blue.main,
        textAlign: 'center'
    },
    subParagraphText: {
        fontFamily: 'Poiret',
        fontWeight: 400,
        marginBottom: 2,
        fontSize: '19px',
        color: theme.palette.blue.main,

    },
    iconStyle: { height: 50, width: 50, color: theme.palette.blue.ternary }
}

export default styles